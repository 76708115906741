$bg-pink-400: rgba(244, 114, 182, 1);
$bg-pink-600: rgba(219, 39, 119, 1);

.react-datepicker-wrapper {
    width: 100%;
    z-index: 100;
}

.react-datepicker__header {
    background-color: white;
    border-bottom: 1px solid rgba(209, 213, 219, 1);
}

.react-datepicker__day--selected {
    // circle
    border-radius: 50%;
    // background-color: #818cf8;
    background-color: #db2777;
}

.react-datepicker__day:hover {
    border-radius: 50%;
    // background-color: #c7d2fe;
    background-color: #f9a8d4;
}

.react-datepicker {
    font-family: inherit !important;
    border: 1px solid rgba(209, 213, 219, 1);
}

.react-datepicker__day--outside-month {
    opacity: 0.3;
}


// for range
.react-datepicker__day--keyboard-selected {
    background-color: $bg-pink-400;
    color: white;
}

.react-datepicker__day--in-range {
    background-color: rgba(219, 39, 119, 1);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: $bg-pink-400;
}

// for time

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    align-content: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: $bg-pink-600; // bg-pink-600
    color: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: $bg-pink-400; // bg-pink-400
    color: white;
}

// for month

.react-datepicker__month-text--keyboard-selected {
    background-color: $bg-pink-600;
    color: white;
}

.react-datepicker__month-text--keyboard-selected:hover {
    background-color: $bg-pink-400;
    color: white;
}